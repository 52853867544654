import { render, staticRenderFns } from "./404.vue?vue&type=template&id=2c58d030&scoped=true&"
import script from "./404.vue?vue&type=script&lang=js&"
export * from "./404.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c58d030",
  null
  
)

export default component.exports